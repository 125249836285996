import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./page/index.css";
import { Navbar } from "./page/navbar";
import Hero from "./page/hero";
import { Services } from "./page/services";
import { About } from "./page/about";
import { Pricing } from "./page/pricing";
import { Partners } from "./page/partners";
import Component from "./page/component";
import { Selfcare } from "./page/selfcare";
import { Contact24 } from "./page/contact";
import { Footer } from "./page/footer";
import { Testimonals } from "./page/testimonals";
import AboutUs from "./page/aboutUs";
import Privacy from "./page/privacy";
import { Business } from "./page/business";
import { CareGiver } from "./page/caregiver";
import { Individual } from "./page/individual";
import { Sport } from "./page/sport";
import Research from "./page/research";
import { Garmin } from "./page/garmin";
import Cookie from "./page/cookies";
import TermsAndConditions from "./page/terms";
import { createRoot } from 'react-dom/client';
import LanguageDropdown from "./page/LanguageDropdown";
import Banner from "./Banner";

const MainContent = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = document.getElementById(location.state.scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  return (
    <div>
      <Banner />
      <div className="relative hero-section" id="hero">
        <Hero />
      </div>
      <div id="partners">
        <Partners />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="component">
        <Component />
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="garmin">
        <Garmin />
      </div>
      <div id="selfcare" className="py-12 md:py-16 lg:py-20">
        <Selfcare />
      </div>
      <div id="testimonals">
        <Testimonals />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div
        className="bg-[url(../assets/images/53e8cf6e-da8a-4a5f-80e8-7860917cc506.png)] bg-cover bg-no-repeat w-full"
        id="contact"
      >
        <Contact24 />
      </div>
      <Footer />
    </div>
  );
};

const App = () => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [isHeroSection, setIsHeroSection] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const heroSection = document.getElementById("hero");
    if (heroSection) {
      const heroRect = heroSection.getBoundingClientRect();
      const isHero =
        heroRect.top >= -90 && heroRect.bottom <= window.innerHeight + 90;
      setIsHeroSection(isHero);
    }

    const currentScrollY = window.scrollY;

    if (currentScrollY < lastScrollY || currentScrollY < 100) {
      setIsNavbarVisible(true);
    } else if (currentScrollY > lastScrollY) {
      setIsNavbarVisible(false);
    }

    setLastScrollY(currentScrollY);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (event.clientY <= 100) {
      setIsNavbarVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [lastScrollY]);

  const navbarClass = `navbar-fixed ${
    isHeroSection ? "" : isNavbarVisible ? "navbar-visible" : "navbar-hidden"
  }`;

  return (
    <Router>
      <div className="main-container mx-auto my-0">
        <div className={navbarClass}>
          <Navbar />
          <LanguageDropdown /> 
        </div>

        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/sv" element={<MainContent />} />
          <Route path="/en" element={<MainContent />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/business" element={<Business />} />
          <Route path="/caregiver" element={<CareGiver />} />
          <Route path="/individual" element={<Individual />} />
          <Route path="/sport" element={<Sport />} />
          <Route path="/research" element={<Research />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="/t&c" element={<TermsAndConditions />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </div>
    </Router>
  );
};

// Render the App
const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<App />);
