import React, { useState, useEffect } from "react";
import type { CarouselApi } from "@relume_io/relume-ui";
import "./testimonals.css";
import clsx from "clsx";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@relume_io/relume-ui";
import { MdVerified, MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

type Testimonial = {
  testimonial: string;
  name: string;
  position: string;
  companyName: string;
  numberOfStars: number;
};

type Props = {
  heading: string;
  description: string;
  testimonials: Testimonial[];
};

export type TestimonalsProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Testimonals = (props: TestimonalsProps) => {
  const { heading, description, testimonials } = {
    ...TestimonalsDefaults,
    ...props,
  } as Props;

  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);
    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section className="testimonial-section">
      <div className="container">
        <h2 className="testimonial-heading">{heading}</h2>

        {/* Verified Reviews Section */}
        <p className="text-lg italic text-gray-600 mb-4 flex items-center justify-center">
          <MdVerified className="mr-2 text-green-500" /> Verified Reviews
        </p>

        <p
          className="testimonial-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="carousel-container relative mx-auto">
          {/* Fading Edges */}
          <div className="fade-left"></div>

          <Carousel
            setApi={setApi}
            opts={{
              loop: true,
              align: "center",
              skipSnaps: false, // Ensures it doesn't skip snaps
            }}
          >
            <CarouselContent className="carousel-content">
              {testimonials.map((testimonial, index) => (
                <CarouselItem key={index} className="carousel-item">
                  <TestimonialCard testimonial={testimonial} />
                </CarouselItem>
              ))}
            </CarouselContent>

            {/* Centered Navigation Arrows */}
            <div className="carousel-controls">
              <CarouselPrevious className="arrow">
                <MdArrowBackIos />
              </CarouselPrevious>
              <CarouselNext className="arrow">
                <MdArrowForwardIos />
              </CarouselNext>
            </div>
          </Carousel>

          <div className="fade-right"></div>
        </div>
      </div>
    </section>
  );
};

const TestimonialCard = ({ testimonial }: { testimonial: Testimonial }) => {
  return (
    <div className="testimonial-card">
      <p className="testimonial-text">{testimonial.testimonial}</p>
      <div className="testimonial-footer">
        <p className="testimonial-name">{testimonial.name}</p>
        <p className="testimonial-position">{testimonial.position}</p>
        <p className="testimonial-position">{testimonial.companyName}</p>
      </div>
    </div>
  );
};

export const TestimonalsDefaults: TestimonalsProps = {
  heading: "Success Stories",
  description: "",
  testimonials: [
    {
      numberOfStars: 5,
      testimonial:
        '"Its a no brainer. It made me feel better, secured, and relieved my inner stress."',
      name: "- Philip",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"It was very powerful. I think My-E-Health became the catalyst for change in life."',
      name: "- Anne",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"I no longer have this inner stress and I feel great. It proved invaluable to me."',
      name: "- Thomas",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"From despair to hope. From grief and work burnout to a calm and balanced life. From zero confidence and emptiness to coping skills and self-belief."',
      name: "- Akhilesh",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"The best thing about the My-E-Health® program was that I could keep working throughout my recovery."',
      name: "- Daniel",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"Last year I thought I wouldn\'t ever compete again. I was very depressed. My health was bad, and it was stressful. It was my perfect storm as many different things just went wrong at the same time."',
      name: "- Isac",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"I felt like I was a passenger in my own life. I didn\'t have time to choose a different direction; I was on this path now and I had to go down it."',
      name: "- Veronica",
      position: "",
      companyName: "",
    },
    {
      numberOfStars: 5,
      testimonial:
        '"The best thing about the My-E-Health® program was that I could keep working throughout my recovery."',
      name: "- John",
      position: "",
      companyName: "",
    },
  ],
};
