import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import { RxChevronRight } from "react-icons/rx";
import "./index.css";

type ImageProps = { alt?: string };
type SectionProps = {
  imageClass: string;
  heading: string;
  description: string;
  button: ButtonProps & { link?: { title: string; url: string } }; // Updated to use link with title and url
};
type Props = { sections: SectionProps[] };

export type ServicesProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Services = (props: ServicesProps) => {
  const { sections = [] } = { ...props, ...ServicesDefaults };
  return (
    <section className="px-[5%] py-16 md:py-24 lg:py-28">
      <div className="mb-12 max-w-lg md:mb-18 lg:mb-20">
        <h2 className="mb-5 text-5xl font-bold md:mb-6 md:text-7xl lg:text-8xl">
          Check Our Services
        </h2>
      </div>
      <div className="container grid grid-cols-1 items-start gap-y-12 md:grid-cols-2 lg:grid-cols-4 md:gap-x-8 md:gap-y-16 lg:gap-x-12">
        {sections.map((section, index) => (
          <div
            key={index}
            className="bg-[#e2e8f0] shadow-lg rounded-lg overflow-hidden flex flex-col h-full"
          >
            <div
              className={`service-img ${section.imageClass} flex-none`}
              aria-label={section.heading}
              style={{ height: "200px" }} // Set a fixed height for the images
            ></div>
            <div className="p-6 flex flex-col flex-grow">
              <h3 className="mb-3 text-xl font-bold md:mb-4 md:text-2xl">
                {section.heading}
              </h3>
              <p className="flex-grow">{section.description}</p>
              <div className="mt-6 flex items-center gap-4 md:mt-8">
                <Button
                  variant={section.button.variant}
                  size={section.button.size}
                  iconRight={section.button.iconRight}
                  iconLeft={section.button.iconLeft}
                  onClick={() =>
                    section.button.link &&
                    (window.location.href = section.button.link.url) // Using link.url instead of path
                  }
                >
                  {section.button.title}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export const ServicesDefaults: ServicesProps = {
  sections: [
    {
      imageClass: "individual-img",
      heading: "Individual",
      description:
        "What benefits do I get as an individual when using My-E-Health?",
      button: {
        title: "Read More",
        variant: "link",
        size: "link",
        iconRight: <RxChevronRight className="size-6" />,
        link: { title: "individual", url: "/individual" }, // Updated link structure
      },
    },
    {
      imageClass: "business-img",
      heading: "Business",
      description: "Why should our business want to use My-E-Health?",
      button: {
        title: "Read More",
        variant: "link",
        size: "link",
        iconRight: <RxChevronRight className="size-6" />,
        link: { title: "business", url: "/business" }, // Updated link structure
      },
    },
    {
      imageClass: "sport-img",
      heading: "Sport",
      description:
        "How can sports teams use My-E-Health, both as a team and individually?",
      button: {
        title: "Read More",
        variant: "link",
        size: "link",
        iconRight: <RxChevronRight className="size-6" />,
        link: { title: "sport", url: "/sport" }, // Updated link structure
      },
    },
    {
      imageClass: "caregiver-img",
      heading: "Caregiver",
      description:
        "Physically and mentally healthy people engage in life and work.",
      button: {
        title: "Read More",
        variant: "link",
        size: "link",
        iconRight: <RxChevronRight className="size-6" />,
        link: { title: "caregiver", url: "/caregiver" }, // Updated link structure
      },
    },
  ],
};

Services.displayName = "Services";
