import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, useMediaQuery } from "@relume_io/relume-ui";
import { motion } from "framer-motion";
import { List } from "phosphor-react";
import "./index.css";

type ImageProps = {
  url?: string;
  src: string;
  alt?: string;
};

type CustomButtonProps = {
  title: string;
  size?: string;
  variant?: string;
  onClick?: () => void;
};

type MegaMenuLink = {
  url: string;
  image: ImageProps;
  title: string;
  description: string;
  button?: CustomButtonProps;
};

type CategoryLink = {
  title: string;
  links: MegaMenuLink[];
};

type MegaMenuLinkProps = {
  categoryLinks: CategoryLink[];
  featuredSections: {
    title: string;
    links: MegaMenuLink[];
  };
  button: CustomButtonProps;
};

type LinkProps = {
  title: string;
  url: string;
  megaMenu?: MegaMenuLinkProps;
};

type Props = {
  logo: ImageProps;
  links: LinkProps[];
  buttons: CustomButtonProps[];
};

export type NavbarProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Navbar = (props: NavbarProps) => {
  const { logo, links, buttons } = {
    ...NavbarDefaults,
    ...props,
  } as Props;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 991px)");
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignIn = () => {
    window.location.href = "https://app.my-e-health.com/account/sign-in";
  };

  const handleSignUp = () => {
    window.location.href =
      "http://ded100755.my-e-health.com:8088/tracker?st=WEBPAGE&si=HomePage&it=CLICK&tl=register.my-e-health.com/start-registration-B2C&ci=HomePage";
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLinkClick = (url: string) => {
    const sectionId = url.split("#")[1];
    if (sectionId) {
      if (location.pathname !== "/") {
        navigate("/", { state: { scrollTo: sectionId } });
      } else {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      navigate(url);
    }
    closeMobileMenu();
  };

  return (
    <nav className="relative flex w-full items-center justify-between bg-white bg-opacity-90 shadow-md rounded-full lg:px-[5%] mx-auto my-4 max-w-7xl">
      <div className="flex w-full lg:flex lg:items-center lg:justify-between">
        <a href={logo.url} className="flex items-center ml-5 lg:ml-0">
          <div className="h-12 md:h-14 lg:h-16 w-auto logo-bg" />
        </a>

        <div className="hidden lg:flex lg:gap-8 lg:items-center">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="text-md font-bold text-black hover:text-gray-700"
              onClick={(e) => {
                e.preventDefault();
                handleLinkClick(link.url);
              }}
            >
              {link.title}
            </a>
          ))}
        </div>

        <button
          className="lg:hidden flex items-center justify-center ml-auto size-12 absolute right-0 mr-4"
          onClick={() => setIsMobileMenuOpen((prev) => !prev)}
          aria-label="Toggle mobile menu"
        >
          <List size={32} className="text-black" />
        </button>

        {isMobileMenuOpen && (
          <div
            className="fixed inset-0 z-20 bg-black bg-opacity-50"
            onClick={closeMobileMenu}
          ></div>
        )}

        {isMobileMenuOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-full right-0 left-0 z-30 mt-2 w-full lg:hidden bg-white rounded-lg shadow-lg py-4 px-6"
          >
            {links.map((link, index) => (
              <div key={index} className="py-2">
                <a
                  href={link.url}
                  className="block text-lg font-bold text-black py-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick(link.url);
                  }}
                >
                  {link.title}
                </a>
              </div>
            ))}
            <div className="mt-4">
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  className="w-full rounded-full mb-2"
                  onClick={
                    button.title === "Sign Up" ? handleSignUp : handleSignIn
                  }
                  style={
                    button.title === "Sign Up"
                      ? { backgroundColor: "#EDED00", color: "black" }
                      : { backgroundColor: "transparent", color: "black" }
                  }
                  aria-label={button.title}
                >
                  {button.title}
                </Button>
              ))}
            </div>
          </motion.div>
        )}

        <div className="hidden lg:flex lg:gap-4">
          {buttons.map((button, index) => (
            <Button
              key={index}
              className="rounded-full"
              onClick={button.title === "Sign Up" ? handleSignUp : handleSignIn}
              style={
                button.title === "Sign Up"
                  ? { backgroundColor: "#EDED00", color: "black" }
                  : { backgroundColor: "transparent", color: "black" }
              }
              aria-label={button.title}
            >
              {button.title}
            </Button>
          ))}
        </div>
      </div>
    </nav>
  );
};

export const NavbarDefaults: NavbarProps = {
  logo: {
    url: "/#",
    src: "", // Add your logo source here
    alt: "Logo image",
  },
  links: [
    { title: "Home", url: "/#hero" },
    { title: "Services", url: "/#services" },
    { title: "About Us", url: "/aboutUs" },
    { title: "Research", url: "/research" },
    { title: "Pricing", url: "/#pricing" },
    { title: "Contact", url: "/#contact" },
  ],
  buttons: [
    {
      title: "Sign Up",
      size: "sm",
    },
    {
      title: "Sign In",
      variant: "secondary",
      size: "sm",
    },
  ],
};

Navbar.displayName = "Navbar";
