import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";
import "./index.css"; // Import the CSS file containing your image references
import { Footer } from "./footer";

type ImageProps = {
  src?: string;
  alt?: string;
  className?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type BusinessProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Business = (props: BusinessProps) => {
  const { heading, description, buttons, image } = {
    ...BusinessDefaults,
    ...props,
  } as Props;
  return (
    <main>
      <section className="px-[5%] py-16 md:py-24 lg:py-28 bg-[#E8F5F4]">
        <div className="container">
          <div className="text-center mb-12">
            <h1 className="mb-5 text-6xl font-bold md:mb-6 md:text-9xl lg:text-10xl">
              {heading}
            </h1>
            <p className="md:text-md">{description}</p>
          </div>
          <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-20 lg:items-center mb-16">
            <div>
              <div className="aspect-video overflow-hidden rounded-lg object-cover image-placeholder" />
            </div>
            <div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-4">
                Why My-E-Health?
              </h2>
              <p className="text-muted-foreground mb-4">
                At My-E-Health, we believe that the mental and physical
                wellbeing of your employees is crucial for the success of your
                business. Our comprehensive suite of services is designed to
                support your team, boost productivity, and create a positive
                work environment.
              </p>
              <ul className="space-y-2 text-muted-foreground mb-6">
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Personalized counselling and assessments</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Tailored wellness programs for your team</span>
                </li>
                <li className="flex items-center space-x-2">
                  <CheckIcon className="h-5 w-5 text-primary" />
                  <span>Improved employee engagement and productivity</span>
                </li>
              </ul>
              <div className="mt-6 flex gap-x-4 md:mt-8">
                {buttons.map((button, index) => (
                  <Button key={index} {...button}>
                    {button.title}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <section className="w-full py-12 md:py-24 lg:py-32">
            <div className="container px-4 md:px-6">
              <div className="mx-auto max-w-5xl">
                <div className="space-y-4 text-center">
                  <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                    What do we bring to the table?
                  </h2>
                  <p className="text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                    Discover how our comprehensive services can transform your
                    organization's health and well-being.
                  </p>
                </div>
                <div className="mx-auto grid max-w-5xl items-center gap-12 py-12 lg:grid-cols-2">
                  <div className="space-y-6">
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Continuity of Care</h3>
                      <p className="text-muted-foreground">
                        Enjoy the peace of mind that comes with our quarterly
                        risk exposure reports, company wellbeing assessments,
                        and strategic planning. We're committed to providing you
                        with the tools and insights you need to make informed
                        decisions and achieve sustainable success.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Holistic Approach</h3>
                      <p className="text-muted-foreground">
                        Our focus extends beyond just treating symptoms. We
                        delve deep to uncover and address the root causes of
                        your challenges, ensuring lasting solutions that improve
                        the overall health and well-being of your organization.
                      </p>
                    </div>
                    <div className="grid gap-2">
                      <h3 className="text-2xl font-bold">Measurable ROI</h3>
                      <p className="text-muted-foreground">
                        With our comprehensive ROI calculations, you'll have a
                        clear understanding of the tangible benefits and cost
                        savings our services can provide. We're committed to
                        delivering a positive impact that you can see and
                        measure.
                      </p>
                    </div>
                  </div>
                  <div className="grid gap-6">
                    <div className="aspect-video overflow-hidden rounded-lg object-cover image-test" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-12 md:py-24 lg:py-32">
            <div className="container grid gap-12 md:grid-cols-2 lg:gap-24">
              <div className="space-y-6">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                  How it works?
                </h2>
                <div className="grid gap-4 text-muted-foreground">
                  <p>
                    My-E-Health is a preventative healthcare platform that
                    empowers you to take control of your well-being. By
                    providing personalized insights and recommendations, we help
                    you make informed decisions about your health and lifestyle.
                  </p>
                  <p>
                    Our platform combines cutting-edge technology with
                    evidence-based medical research to give you a comprehensive
                    understanding of your health status. From tracking your
                    vital signs to analyzing your lifestyle habits, we provide
                    you with the tools and guidance you need to proactively
                    manage your health.
                  </p>
                  <p>
                    With My-E-Health, you can stay ahead of potential health
                    issues, identify areas for improvement, and take actionable
                    steps to optimize your overall well-being. Embrace a
                    preventative approach to healthcare and empower yourself to
                    live a healthier, more fulfilling life.
                  </p>
                </div>
              </div>
              <div className="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center order-first image-how-it-works" />
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export const BusinessDefaults: BusinessProps = {
  heading: "Business",
  description: "",
  buttons: [],
  image: {
    className: "image-placeholder",
    alt: "Placeholder image",
  },
};

Business.displayName = "Business";

function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}

function XIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  );
}
