import React from "react";

export default function Privacy() {
  return (
    <div className="bg-background text-foreground">
      <main className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Privacy Policy
          </h1>
          <div className="mt-8 space-y-8">
            <section>
              <h2 className="text-2xl font-semibold">What is personal data?</h2>
              <p className="mt-4 text-muted-foreground">
                Personal data is any information that can be used to identify
                you, either directly or indirectly. This includes your name,
                email address, phone number, and other information that is
                linked to you.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                How is your personal data protected?
              </h2>
              <p className="mt-4 text-muted-foreground">
                All use of personal data, for example: collecting, compiling
                and/or storage, is covered by EU laws according to GDPR. All
                information collected is stored according to established and
                documented routines to ensure the integrity and availability of
                personal data. My-E-Health does not store personal data longer
                than is necessary for treatment or the contractual period of
                your subscription. Exceptions will be made if there is a legal
                requirement to do so My-E-Health does not share personal data
                unless required by law.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">Who is responsible?</h2>
              <p className="mt-4 text-muted-foreground">
                My-E-Health is responsible for handling your personal data. If
                you want to have an extract of the personal data we have
                collected about you, correct any information or you would like
                to delete your personal data in our register then contact
                info@my-e-health.com. We will update this policy frequently; the
                last update was done 1 September 2019. If you have any questions
                about the policy, you can contact us at info@my-e-health.com.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                What information do we collect?
              </h2>
              <p className="mt-4 text-muted-foreground">
                My-E-Health collects personal data that: You provide us,
                information that is automatically is gathered or generated from
                using our services such as assessments, name, education, health
                status, your internet connection, billing information and
                information about your account (not the password).
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                Why do we collect personal data about you?
              </h2>
              <p className="mt-4 text-muted-foreground">
                My-E-Health collects data so that we can deliver our proactive
                services to you in the best possible way. Collected data is
                frequently used to improve our services and analysis.
                My-E-Health uses your personal data to personalize our services
                to you. These are based upon the terms agreed between us and
                based upon your assessment data and usage. My-E-Health can
                recommend support or treatment based upon this data.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                For what purpose do we use the information?
              </h2>
              <p className="mt-4 text-muted-foreground">
                My-E-Health use of your personal data is to provide you with
                feedback, recommendations and tools based upon changes in your
                data and other comparable users 2019. My-E-Health uses your
                information to analyze your trends and your deviations and
                correlations thereto to assist in helping you to achieve defined
                long- or short-term goals. Your assigned My-E-Health caregivers
                get access to personal data. However, they are restricted from
                divulging your data to third parties. The caregivers are not
                allowed to use your personal data for any other purpose than to
                deliver services connected to the My-E-Health platform. Data
                average mean scores (for groups of individuals) for taken
                assessments and related information, that is (data without
                identifiable information and only averages) can be shown on your
                company dashboard where department and company averages, trends
                and deviations can be displayed. Your personal data can be used
                to provide user-friendly services customized for you.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                When does My-E-Health collect the information?
              </h2>
              <p className="mt-4 text-muted-foreground">
                My-E-Health collects information about you when you are creating
                an account and when you are using our services. We register
                information about the services and the web pages you use and how
                you interact with other web pages. My-E-Health also registers
                information about what kind of device you use to provide
                improved service when connected. My-E-Health will record video
                sessions with you and your caregiver for purpose of
                quality-control. These sessions will be made available to you
                and the caregiver and My-E-Healthu2019s approved quality
                assurance psychologists only. The recorded information will be
                handled with secrecy within My-E-Health where only the relevant
                persons will have access. When you use our services will we
                gather information about your geographical location to collect
                statistics and help our system to evaluate that it is you and
                not someone else trying to log in.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                Sharing of your personal data
              </h2>
              <p className="mt-4 text-muted-foreground">
                Information about your usage of our services can be shared with
                specially selected partners or for research purposes 2013 used
                in the context for why it is gathered. Research is naturally
                important to identify deviations, treatment methods and
                development other related and analytical solutions, however, no
                personal identifying information will be made available to
                My-E-Health approved researches. No external researches will be
                able to access your personal data. Integrity-based reports that
                can be produced and published for research purposes. My-E-Health
                will always strive to protect your secrecy and your integrity.
                As integrity is key to offering a solid framework for analysis,
                trends and correlations to demographic data and your personal
                information, no identifying or personal data will be made public
                in publications nor within company use. However, it you are at
                risk and this has been identified via our platform we have the
                obligation to assist you in taking the next step. If legally
                required or we suspect that there have been illegal activities
                using our services, we will on request share information about
                your usage to authorities. My-E-Health can share personal
                information about you if your My-E-Health Caregivers believe
                that it is in your best health interests and can assist in
                preventing or avoiding illness. My-E-Health will not share,
                sell, or distribute your personal information in any other way
                than described within this policy document.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold">
                Use of analytic tools and cookies
              </h2>
              <p className="mt-4 text-muted-foreground">
                We are continually working to improve your user experience
                within our online ecosystem, our analysis tools and services. To
                do that My-E-Health could register and analyze your information
                in relation to you and another user about interacting with
                My-E-Health.com. However, this will not be made public.
                My-E-Health can use Ai and other techniques for recognizing you
                as a user and analyzing data from you and other users.
              </p>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}
