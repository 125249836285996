import React from "react";
import { Button } from "@relume_io/relume-ui";
import type { ButtonProps } from "@relume_io/relume-ui";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  buttons: ButtonProps[];
  image: ImageProps;
};

export type GarminProps = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Garmin = (props: GarminProps) => {
  const { heading, description, buttons, image } = {
    ...GarminDefaults,
    ...props,
  } as Props;

  return (
    <section className="relative px-[5%] mb-16 sm:px-6 lg:px-[5%]">
      <div className="container mx-auto py-16 text-center lg:py-24">
        <div className="max-w-2xl mx-auto bg-white bg-opacity-80 p-6 rounded-lg shadow-lg">
          <h1 className="mb-6 text-4xl font-bold text-black md:text-5xl lg:text-6xl">
            {heading}
          </h1>
          <p className="mb-8 text-base text-gray-700 sm:text-lg md:text-xl">
            {description}
          </p>
          <div className="mt-8 flex justify-center gap-4">
            {buttons.map((button, index) => (
              <Button
                key={index}
                className="rounded-full hover:scale-105 transition-transform"
                {...button}
              >
                {button.title}
              </Button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-12 md:grid-cols-3 lg:grid-cols-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 bg-white text-black rounded-lg shadow-md transition-transform duration-300 hover:scale-105 hover:bg-[#E5F7F1] hover:shadow-lg"
            >
              <feature.icon className="h-8 w-8 mb-2 text-[#5BB7AF] transition-transform duration-300 hover:rotate-6" />
              <p className="text-sm font-semibold">{feature.title}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="absolute inset-0 -z-10">
        <div className="garmin-image size-full object-cover" />
        <div className="absolute inset-0 bg-black/30" />
      </div>
    </section>
  );
};

export const GarminDefaults: GarminProps = {
  heading: "Empower Your Health with Garmin",
  description:
    "Make the most of your Garmin watch data to easily track your health and well-being. Our app works smoothly with your Garmin device, offering detailed insights and personalized tips for a healthier lifestyle, from heart rate monitoring to stress tracking.",
  buttons: [],
  image: {
    src: "", // The image is handled through CSS
    alt: "Garmin health tracking features",
  },
};

const features = [
  { title: "Nap Detection", icon: MonitorCheckIcon },
  { title: "HRV Status", icon: FlagIcon },
  { title: "Wrist Based Heart Rate", icon: HeartPulseIcon },
  { title: "Stress Tracking", icon: TimerIcon },
  { title: "Women Health Tracking", icon: HeartPulseIcon },
  { title: "Health Snapshot", icon: ScanIcon },
  { title: "Pulse Ox Sensor", icon: SignalIcon },
  { title: "Meditation", icon: MedalIcon },
  { title: "Mindful Breathing", icon: HeartPulseIcon },
  { title: "Hydration", icon: GlassWaterIcon },
];

function FlagIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z" />
      <line x1="4" x2="4" y1="22" y2="15" />
    </svg>
  );
}

function GlassWaterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.2 22H8.8a2 2 0 0 1-2-1.79L5 3h14l-1.81 17.21A2 2 0 0 1 15.2 22Z" />
      <path d="M6 12a5 5 0 0 1 6 0 5 5 0 0 0 6 0" />
    </svg>
  );
}

function HeartPulseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
      <path d="M3.22 12H9.5l.5-1 2 4.5 2-7 1.5 3.5h5.27" />
    </svg>
  );
}

function MedalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.21 15 2.66 7.14a2 2 0 0 1 .13-2.2L4.4 2.8A2 2 0 0 1 6 2h12a2 2 0 0 1 1.6.8l1.6 2.14a2 2 0 0 1 .14 2.2L16.79 15" />
      <path d="M11 12 5.12 2.2" />
      <path d="m13 12 5.88-9.8" />
      <path d="M8 7h8" />
      <circle cx="12" cy="17" r="5" />
      <path d="M12 18v-2h-.5" />
    </svg>
  );
}

function MonitorCheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 10 2 2 4-4" />
      <rect width="20" height="14" x="2" y="3" rx="2" />
      <path d="M12 17v4" />
      <path d="M8 21h8" />
    </svg>
  );
}

function ScanIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 7V5a2 2 0 0 1 2-2h2" />
      <path d="M17 3h2a2 2 0 0 1 2 2v2" />
      <path d="M21 17v2a2 2 0 0 1-2 2h-2" />
      <path d="M7 21H5a2 2 0 0 1-2-2v-2" />
    </svg>
  );
}

function SignalIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2 20h.01" />
      <path d="M7 20v-4" />
      <path d="M12 20v-8" />
      <path d="M17 20V8" />
      <path d="M22 4v16" />
    </svg>
  );
}

function TimerIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="10" x2="14" y1="2" y2="2" />
      <line x1="12" x2="15" y1="14" y2="11" />
      <circle cx="12" cy="14" r="8" />
    </svg>
  );
}
